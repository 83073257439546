@import 'styles/includes.nordr.scss';

.PageNotFound {
    &--Wrapper {
        margin: 0 auto;
        background-color: $colorBrownLight20;
    }

    &__Container {
        height: 100%;
        background: rgba(35, 45, 80);
    }

    &__Superscript {
      font-size: 1.4rem;
      font-weight: 600;
      letter-spacing: 2px;
      color: #fff;
      margin-bottom: 16px;
      display: block;
    }

    &__Hero {
        position: relative;
        height: calc(100vh + 10px);
    }

    &__HeroImage {
        margin-top: -1px; // Hack that hides Header border
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        @include media(lg) {
        }
    }

    &__Content {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        height: 100vh;
        padding-left: 20px;
    }

    &__SubHeader {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.25px;
        color: #fff;
        padding-bottom: 8px;
    }

    &__Headline {
        font-size: 5.6rem;
        font-weight: 500;
        line-height: 1.25;
        letter-spacing: 1.8px;
        margin-bottom: 16px;
        color: #fff;
    }

    &__Info {
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: 0.2px;
        margin-bottom: 45px;
        color: #fff;
    }

    &__Btn {
        width: auto;
        height: auto;
        line-height: initial;
        padding: 12px 24px;
        margin-right: 16px;

        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.33;
        letter-spacing: 1px;

        &--White {
            background-color: #fff;
            color: #000;
        }
    }
}
