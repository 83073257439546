@import 'styles/includes.folkhem.scss';

.PageNotFound {
    overflow: hidden;

    &__Content {
        @include u-wrap-folkhem(content);
        padding: 89px 0;
    }

    &__Headline {
        font-size: 3.6rem;
        font-weight: 300;
        color: rgba($colorBrandDark, 0.8);
        margin-bottom: 8px;

        @include media(md) {
            font-size: 4.8rem;
        }
    }

    &__Info {
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.75;
        letter-spacing: 0.2px;
        color: $colorBlack;
        margin-bottom: 40px;
    }

    &__Btn {
        width: auto;
        height: auto;
        line-height: initial;
        padding: 16px 16px;
        letter-spacing: 1.3px;
        line-height: 1;
        font-size: 1.6rem;
        border-radius: 2px;
        font-family: $fontFamilyBase;
        background-color: $colorBrand;
    }
}
